import { cn } from '@/utils/classes'
import React from 'react'

interface ContainerProps {
    className?: string
    children?: React.ReactNode
}

export function Container({ className, children }: ContainerProps) {
    return <div className={cn('mx-auto w-full max-w-[1500px] px-4 sm:px-6', className)}>{children}</div>
}
