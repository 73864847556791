import { Icon } from '@/components/icons'
import { useTheme } from '@/components/theme-provider'
import { __ } from '@/lib/utils'
import { buttonStyles, Tooltip } from 'ui'

export function ThemeSwitcher() {
    const { theme, setTheme } = useTheme()

    return (
        <Tooltip>
            <Tooltip.Trigger
                aria-label={'Switch to ' + theme === 'light' ? 'dark' : 'light' + 'mode'}
                className={buttonStyles({
                    appearance: 'plain',
                    size: 'square-petite',
                    shape: 'circle',
                })}
                onPress={() => setTheme(theme === 'light' ? 'dark' : 'light')}
            >
                <Icon
                    iconName="Sun"
                    className="scale-100 rotate-0 transition-all dark:scale-0 dark:-rotate-90"
                    size="xl"
                />
                <Icon
                    iconName="Moon"
                    className="absolute scale-0 rotate-90 transition-all dark:scale-100 dark:rotate-0"
                    size="xl"
                />
            </Tooltip.Trigger>
            <Tooltip.Content>{__('Pilih tema yang bikin kamu nyaman')}</Tooltip.Content>
        </Tooltip>
    )
}
