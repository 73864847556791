const Ziggy = {
    url: 'https:\/\/nisally.com',
    port: null,
    defaults: {},
    routes: {
        'sanctum.csrf-cookie': { uri: 'sanctum\/csrf-cookie', methods: ['GET', 'HEAD'] },
        'socialite.redirect': {
            uri: 'login\/{provider}\/redirect',
            methods: ['GET', 'HEAD'],
            parameters: ['provider'],
        },
        register: { uri: 'register', methods: ['GET', 'HEAD'] },
        login: { uri: 'login', methods: ['GET', 'HEAD'] },
        'password.request': { uri: 'forgot-password', methods: ['GET', 'HEAD'] },
        'password.email': { uri: 'forgot-password', methods: ['POST'] },
        'password.reset': { uri: 'reset-password\/{token}', methods: ['GET', 'HEAD'], parameters: ['token'] },
        'password.store': { uri: 'reset-password', methods: ['POST'] },
        'verification.notice': { uri: 'verify-email', methods: ['GET', 'HEAD'] },
        'verification.verify': {
            uri: 'verify-email\/{id}\/{hash}',
            methods: ['GET', 'HEAD'],
            parameters: ['id', 'hash'],
        },
        'verification.send': { uri: 'email\/verification-notification', methods: ['POST'] },
        'password.confirm': { uri: 'confirm-password', methods: ['GET', 'HEAD'] },
        'password.update': { uri: 'password', methods: ['PUT'] },
        logout: { uri: 'logout', methods: ['POST'] },
        home: { uri: '\/', methods: ['GET', 'HEAD'] },
        dashboard: { uri: 'dashboard', methods: ['GET', 'HEAD'] },
        'profile.edit': { uri: 'profile', methods: ['GET', 'HEAD'] },
        'profile.update': { uri: 'profile', methods: ['PATCH'] },
        'profile.destroy': { uri: 'profile', methods: ['DELETE'] },
        'articles.search': { uri: 'articles\/search', methods: ['GET', 'HEAD'] },
        'categories.show': {
            uri: 'articles\/categories\/{category}',
            methods: ['GET', 'HEAD'],
            parameters: ['category'],
            bindings: { category: 'slug' },
        },
        'tags.show': {
            uri: 'articles\/tags\/{tag}',
            methods: ['GET', 'HEAD'],
            parameters: ['tag'],
            bindings: { tag: 'id' },
        },
        'articles.show': {
            uri: 'articles\/{article}',
            methods: ['GET', 'HEAD'],
            wheres: { article: '^(?!latest|trending|most-likes|year|month|week|all-time)[a-z0-9-]+$' },
            parameters: ['article'],
            bindings: { article: 'slug' },
        },
        'articles.index': { uri: 'articles\/{key?}', methods: ['GET', 'HEAD'], parameters: ['key'] },
        'articles.like': {
            uri: 'articles\/{article}\/like',
            methods: ['POST'],
            parameters: ['article'],
            bindings: { article: 'slug' },
        },
        'comments.reply': {
            uri: 'comments-reply\/{comment}',
            methods: ['POST'],
            parameters: ['comment'],
            bindings: { comment: 'id' },
        },
        'comments.report': {
            uri: 'comments-report\/{comment}',
            methods: ['PUT'],
            parameters: ['comment'],
            bindings: { comment: 'id' },
        },
        'comments.like': {
            uri: 'comments-like\/{comment}',
            methods: ['POST'],
            parameters: ['comment'],
            bindings: { comment: 'id' },
        },
        'comments.pin': {
            uri: 'comments-pin\/{comment}',
            methods: ['PUT'],
            parameters: ['comment'],
            bindings: { comment: 'id' },
        },
        'comments.store': {
            uri: '{article}\/comments',
            methods: ['POST'],
            parameters: ['article'],
            bindings: { article: 'slug' },
        },
        'comments.update': {
            uri: '{article}\/comments\/{comment}',
            methods: ['PUT', 'PATCH'],
            parameters: ['article', 'comment'],
            bindings: { article: 'slug', comment: 'id' },
        },
        'comments.destroy': {
            uri: '{article}\/comments\/{comment}',
            methods: ['DELETE'],
            parameters: ['article', 'comment'],
            bindings: { article: 'slug', comment: 'id' },
        },
        'discussions.reply': {
            uri: 'discussions-reply\/{comment}',
            methods: ['POST'],
            parameters: ['comment'],
            bindings: { comment: 'id' },
        },
        'discussions.report': {
            uri: 'discussions-report\/{comment}',
            methods: ['PUT'],
            parameters: ['comment'],
            bindings: { comment: 'id' },
        },
        'discussions.like': {
            uri: 'discussions-like\/{comment}',
            methods: ['POST'],
            parameters: ['comment'],
            bindings: { comment: 'id' },
        },
        'discussions.pin': {
            uri: 'discussions-pin\/{comment}',
            methods: ['PUT'],
            parameters: ['comment'],
            bindings: { comment: 'id' },
        },
        'discussions.store': {
            uri: '{product}\/discussions',
            methods: ['POST'],
            parameters: ['product'],
            bindings: { product: 'slug' },
        },
        'discussions.update': {
            uri: '{product}\/discussions\/{discussion}',
            methods: ['PUT', 'PATCH'],
            parameters: ['product', 'discussion'],
            bindings: { product: 'slug' },
        },
        'discussions.destroy': {
            uri: '{product}\/discussions\/{discussion}',
            methods: ['DELETE'],
            parameters: ['product', 'discussion'],
            bindings: { product: 'slug' },
        },
        'internal-articles.index': { uri: 'internal-articles', methods: ['GET', 'HEAD'] },
        'internal-articles.create': { uri: 'internal-articles\/create', methods: ['GET', 'HEAD'] },
        'internal-articles.store': { uri: 'internal-articles', methods: ['POST'] },
        'internal-articles.edit': {
            uri: 'internal-articles\/{article}\/edit',
            methods: ['GET', 'HEAD'],
            parameters: ['article'],
            bindings: { article: 'slug' },
        },
        'internal-articles.update': {
            uri: 'internal-articles\/{article}',
            methods: ['PUT', 'PATCH'],
            parameters: ['article'],
            bindings: { article: 'slug' },
        },
        'internal-articles.destroy': {
            uri: 'internal-articles\/{article}',
            methods: ['DELETE'],
            parameters: ['article'],
            bindings: { article: 'slug' },
        },
        'internal-articles.approve': {
            uri: 'internal-articles\/approve\/{article}',
            methods: ['PUT'],
            parameters: ['article'],
            bindings: { article: 'slug' },
        },
        'categories.index': { uri: 'categories', methods: ['GET', 'HEAD'] },
        'categories.create': { uri: 'categories\/create', methods: ['GET', 'HEAD'] },
        'categories.store': { uri: 'categories', methods: ['POST'] },
        'categories.edit': {
            uri: 'categories\/{category}\/edit',
            methods: ['GET', 'HEAD'],
            parameters: ['category'],
            bindings: { category: 'id' },
        },
        'categories.update': {
            uri: 'categories\/{category}',
            methods: ['PUT', 'PATCH'],
            parameters: ['category'],
            bindings: { category: 'id' },
        },
        'categories.destroy': {
            uri: 'categories\/{category}',
            methods: ['DELETE'],
            parameters: ['category'],
            bindings: { category: 'id' },
        },
        'tags.index': { uri: 'tags', methods: ['GET', 'HEAD'] },
        'tags.create': { uri: 'tags\/create', methods: ['GET', 'HEAD'] },
        'tags.store': { uri: 'tags', methods: ['POST'] },
        'tags.edit': {
            uri: 'tags\/{tag}\/edit',
            methods: ['GET', 'HEAD'],
            parameters: ['tag'],
            bindings: { tag: 'id' },
        },
        'tags.update': { uri: 'tags\/{tag}', methods: ['PUT', 'PATCH'], parameters: ['tag'], bindings: { tag: 'id' } },
        'tags.destroy': { uri: 'tags\/{tag}', methods: ['DELETE'], parameters: ['tag'], bindings: { tag: 'id' } },
        'products.index': { uri: 'products', methods: ['GET', 'HEAD'] },
        'products.create': { uri: 'products\/create', methods: ['GET', 'HEAD'] },
        'products.store': { uri: 'products', methods: ['POST'] },
        'products.show': {
            uri: 'products\/{product}',
            methods: ['GET', 'HEAD'],
            parameters: ['product'],
            bindings: { product: 'slug' },
        },
        'products.edit': {
            uri: 'products\/{product}\/edit',
            methods: ['GET', 'HEAD'],
            parameters: ['product'],
            bindings: { product: 'slug' },
        },
        'products.update': {
            uri: 'products\/{product}',
            methods: ['PUT', 'PATCH'],
            parameters: ['product'],
            bindings: { product: 'slug' },
        },
        'products.destroy': {
            uri: 'products\/{product}',
            methods: ['DELETE'],
            parameters: ['product'],
            bindings: { product: 'slug' },
        },
        'internal-products.index': { uri: 'internal-products', methods: ['GET', 'HEAD'] },
        'internal-products.create': { uri: 'internal-products\/create', methods: ['GET', 'HEAD'] },
        'internal-products.store': { uri: 'internal-products', methods: ['POST'] },
        'internal-products.edit': {
            uri: 'internal-products\/{product}\/edit',
            methods: ['GET', 'HEAD'],
            parameters: ['product'],
            bindings: { product: 'slug' },
        },
        'internal-products.update': {
            uri: 'internal-products\/{product}',
            methods: ['PUT', 'PATCH'],
            parameters: ['product'],
            bindings: { product: 'slug' },
        },
        'internal-products.destroy': {
            uri: 'internal-products\/{product}',
            methods: ['DELETE'],
            parameters: ['product'],
            bindings: { product: 'slug' },
        },
        'carts.delete-bulk': { uri: 'carts', methods: ['POST'] },
        'cart.store': {
            uri: 'carts\/add-to-cart\/{product}',
            methods: ['POST'],
            parameters: ['product'],
            bindings: { product: 'slug' },
        },
        'cart.update': { uri: 'carts\/update-quantity\/{id}', methods: ['PUT'], parameters: ['id'] },
        'carts.index': { uri: 'carts', methods: ['GET', 'HEAD'] },
        'carts.destroy': {
            uri: 'carts\/delete\/{cart}',
            methods: ['DELETE'],
            parameters: ['cart'],
            bindings: { cart: 'id' },
        },
        'carts.quantity': {
            uri: 'carts\/quantity\/{cart}',
            methods: ['POST'],
            parameters: ['cart'],
            bindings: { cart: 'id' },
        },
        'users.index': { uri: 'users', methods: ['GET', 'HEAD'] },
        'users.show': {
            uri: 'users\/{user}',
            methods: ['GET', 'HEAD'],
            parameters: ['user'],
            bindings: { user: 'id' },
        },
        'users.edit': {
            uri: 'users\/{user}\/edit',
            methods: ['GET', 'HEAD'],
            parameters: ['user'],
            bindings: { user: 'id' },
        },
        'users.update': {
            uri: 'users\/{user}',
            methods: ['PUT', 'PATCH'],
            parameters: ['user'],
            bindings: { user: 'id' },
        },
        'users.destroy': { uri: 'users\/{user}', methods: ['DELETE'], parameters: ['user'], bindings: { user: 'id' } },
        'chats.index': { uri: 'chats', methods: ['GET', 'HEAD'] },
        'chats.show': {
            uri: 'chats\/{user}',
            methods: ['GET', 'HEAD'],
            parameters: ['user'],
            bindings: { user: 'username' },
        },
        'chats.store': {
            uri: 'chats\/{user}',
            methods: ['POST'],
            parameters: ['user'],
            bindings: { user: 'username' },
        },
        'invoices.store': { uri: 'invoices', methods: ['POST'] },
        'invoices.index': { uri: 'invoices', methods: ['GET', 'HEAD'] },
        'invoices.show': {
            uri: 'invoices\/{invoice}',
            methods: ['GET', 'HEAD'],
            parameters: ['invoice'],
            bindings: { invoice: 'id' },
        },
        'invoices.detail': {
            uri: 'invoices\/{invoice}\/detail',
            methods: ['GET', 'HEAD'],
            parameters: ['invoice'],
            bindings: { invoice: 'id' },
        },
        'invoices.print': { uri: 'invoices\/print\/{invoice}', methods: ['GET', 'HEAD'], parameters: ['invoice'] },
        language: { uri: 'language', methods: ['POST'] },
        'series.next': {
            uri: 'series\/next\/{series}\/{chapter}\/{slug}',
            methods: ['POST'],
            parameters: ['series', 'chapter', 'slug'],
        },
        'series.prev': {
            uri: 'series\/prev\/{series}\/{chapter}\/{slug}',
            methods: ['POST'],
            parameters: ['series', 'chapter', 'slug'],
        },
        'series.show-article': {
            uri: 'series\/{series}\/{chapter}\/{slug}',
            methods: ['GET', 'HEAD'],
            parameters: ['series', 'chapter', 'slug'],
        },
        'series.index': { uri: 'series', methods: ['GET', 'HEAD'] },
        'series.create': { uri: 'series\/create', methods: ['GET', 'HEAD'] },
        'series.store': { uri: 'series', methods: ['POST'] },
        'series.show': {
            uri: 'series\/{series}',
            methods: ['GET', 'HEAD'],
            parameters: ['series'],
            bindings: { series: 'slug' },
        },
        'series.edit': {
            uri: 'series\/{series}\/edit',
            methods: ['GET', 'HEAD'],
            parameters: ['series'],
            bindings: { series: 'slug' },
        },
        'series.update': {
            uri: 'series\/{series}',
            methods: ['PUT', 'PATCH'],
            parameters: ['series'],
            bindings: { series: 'slug' },
        },
        'series.destroy': {
            uri: 'series\/{series}',
            methods: ['DELETE'],
            parameters: ['series'],
            bindings: { series: 'slug' },
        },
        'shortener.index': {
            uri: '{shortener}',
            methods: ['GET', 'HEAD'],
            parameters: ['shortener'],
            bindings: { shortener: 'short_url' },
        },
    },
}
if (typeof window !== 'undefined' && typeof window.Ziggy !== 'undefined') {
    Object.assign(Ziggy.routes, window.Ziggy.routes)
}
export { Ziggy }
