import type { ColorSwatchPickerItemProps, ColorSwatchPickerProps } from 'react-aria-components'
import { ColorSwatchPickerItem, ColorSwatchPicker as ColorSwatchPickerPrimitive } from 'react-aria-components'
import { tv } from 'tailwind-variants'

import { ColorSwatch } from './color-swatch'
import { composeTailwindRenderProps, focusRing } from './primitive'

const ColorSwatchPicker = ({ children, className, layout = 'grid', ...props }: ColorSwatchPickerProps) => {
    return (
        <ColorSwatchPickerPrimitive
            layout={layout}
            {...props}
            className={composeTailwindRenderProps(className, 'flex gap-1')}
        >
            {children}
        </ColorSwatchPickerPrimitive>
    )
}

const itemStyles = tv({
    extend: focusRing,
    base: 'relative rounded disabled:opacity-50',
})

const SwatchPickerItem = (props: ColorSwatchPickerItemProps) => {
    return (
        <ColorSwatchPickerItem {...props} className={itemStyles}>
            {({ isSelected }) => (
                <>
                    <ColorSwatch />
                    {isSelected && (
                        <div className="ring-fg/30 absolute top-0 left-0 h-full w-full rounded-[calc(var(--radius-lg)-3.9px)] rounded-md ring-1 outline-hidden forced-color-adjust-none ring-inset" />
                    )}
                </>
            )}
        </ColorSwatchPickerItem>
    )
}

ColorSwatchPicker.Item = SwatchPickerItem

export { ColorSwatchPicker }
