import React, { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { CommandMenu } from 'ui'
import { router, usePage } from '@inertiajs/react'
import axios from 'axios'
import { Icon } from '@/components/icons'
import { ArticleSearchData, PageData } from '@/types/generated'
import { __ } from '@/lib/utils'
import { useMediaQuery } from '@/utils/use-media-query'

const cmdic = 'rounded-md! py-2! flex! gap-x-1!'

export interface OpenCloseProps {
    open: boolean
    setOpen?: (isOpen: boolean) => void
}

export function CommandPalette({ open, setOpen }: OpenCloseProps) {
    const { auth } = usePage<PageData>().props
    const [results, setResults] = useState([])
    const [search, setSearch] = useState('')
    React.useEffect(() => {
        const down = (e: KeyboardEvent) => {
            if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
                e.preventDefault()
                // @ts-ignore
                setOpen((open: boolean) => !open)
            }
        }

        document.addEventListener('keydown', down)

        return () => document.removeEventListener('keydown', down)
    }, [setOpen])

    React.useEffect(() => {
        if (setOpen) {
            setOpen(false)
        }
    }, [setOpen])

    const debouncedSearch = useCallback(
        debounce(async (value) => {
            const { data } = await axios(
                route('articles.search', {
                    search: value,
                }),
            )
            setResults(data)
        }, 500),
        [],
    )

    function searchHandler(value: string) {
        setSearch(value)
        debouncedSearch(value)
    }

    useEffect(() => {
        if (setOpen) {
            router.on('navigate', () => setOpen(false))
        }
    }, [])

    const isDesktop = useMediaQuery('(min-width: 1024px)')
    return (
        <CommandMenu isOpen={open} onOpenChange={setOpen} classNames={{ content: 'backdrop-blur-sm bg-overlay/80' }}>
            <CommandMenu.Input
                autoFocus={isDesktop}
                placeholder={__('Mau mencari apa?')}
                value={search}
                onValueChange={searchHandler}
            />
            <CommandMenu.List className="space-y-1">
                {results.length > 0 ? (
                    results.map((article: ArticleSearchData) => (
                        <CommandMenu.Item className={cmdic} key={article.id} onSelect={() => router.get(article.href)}>
                            <Icon iconName="JournalText" size="xl" appearance="text" intent="theme" />
                            {article.title}
                        </CommandMenu.Item>
                    ))
                ) : (
                    <>
                        {auth.user ? (
                            <React.Fragment key={1}>
                                <CommandMenu.Section key={`1-section`} heading={__('Navigasi')}>
                                    <CommandMenu.Item className={cmdic} onSelect={() => router.get(route('dashboard'))}>
                                        <Icon iconName="Speedometer" size="xl" appearance="text" intent="theme" />
                                        {__('Dasbor')}
                                    </CommandMenu.Item>
                                    <CommandMenu.Item
                                        className={cmdic}
                                        onSelect={() => router.get(route('profile.edit'))}
                                    >
                                        <Icon iconName="Gear" size="xl" appearance="text" intent="theme" />
                                        {__('Pengaturan')}
                                    </CommandMenu.Item>
                                    <CommandMenu.Item
                                        className={cmdic}
                                        onSelect={() => router.get(route('articles.index'))}
                                    >
                                        <Icon iconName="JournalText" size="xl" appearance="text" intent="theme" />
                                        {__('Artikel')}
                                    </CommandMenu.Item>
                                    <CommandMenu.Item className={cmdic} onSelect={() => router.post(route('logout'))}>
                                        <Icon iconName="BoxArrowRight" size="xl" appearance="text" intent="theme" />
                                        {__('Keluar')}
                                    </CommandMenu.Item>
                                </CommandMenu.Section>
                            </React.Fragment>
                        ) : (
                            <React.Fragment key={1}>
                                <CommandMenu.Section key={`1-section`} heading="Navigation">
                                    <CommandMenu.Item className={cmdic} onSelect={() => router.get(route('home'))}>
                                        <Icon iconName="House" size="xl" appearance="text" intent="theme" />
                                        {__('Beranda')}
                                    </CommandMenu.Item>
                                    <CommandMenu.Item
                                        className={cmdic}
                                        onSelect={() => router.get(route('articles.index'))}
                                    >
                                        <Icon iconName="JournalText" size="xl" appearance="text" intent="theme" />
                                        {__('Artikel')}
                                    </CommandMenu.Item>
                                    <CommandMenu.Item className={cmdic} onSelect={() => router.get(route('login'))}>
                                        <Icon iconName="BoxArrowLeft" size="xl" appearance="text" intent="theme" />
                                        {__('Masuk')}
                                    </CommandMenu.Item>
                                    <CommandMenu.Item className={cmdic} onSelect={() => router.get(route('register'))}>
                                        <Icon iconName="Forward" size="xl" appearance="text" intent="theme" />
                                        {__('Daftar')}
                                    </CommandMenu.Item>
                                </CommandMenu.Section>
                            </React.Fragment>
                        )}
                    </>
                )}
            </CommandMenu.List>
        </CommandMenu>
    )
}
