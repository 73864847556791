import { Avatar, Badge, buttonStyles, Card, Heading } from 'ui'
import { Container } from '@/components/container'
import { __ } from '@/lib/utils'
import { Link } from 'react-aria-components'
import { Icon } from './icons'
import { SeriesData } from '@/types/generated'
import { cn } from '@/utils/classes'

export function HomeSperator({ className, series }: { className?: string; series: Array<SeriesData> }) {
    const appName = import.meta.env.VITE_APP_NAME
    return (
        <>
            <div className={cn('mt-12 w-full border-t', className)}>
                <Container>
                    <div className="flex items-center justify-between pt-6 pb-2">
                        <div className="flex flex-col gap-y-2">
                            <div className="text-md font-poppins font-semibold text-wrap md:text-xl lg:text-2xl">
                                {__('Kumpulan Seri')}
                            </div>
                            <div className="text-muted-fg text-sm md:text-base">
                                {__('Yuk, kita bersama kembangkan kemampuan!')}
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
            <div>
                <Container>
                    <Heading level={3}>
                        {__('Rasakan serunya belajar secara seri dan nikmati semua manfaatnya!')}
                    </Heading>
                    <div className="mt-4 mb-6 grid grid-cols-1 gap-x-10 gap-y-4 md:mb-8 md:grid-cols-2 lg:mt-8 lg:mb-10 lg:gap-y-8">
                        <div className="flex cursor-default space-x-4">
                            <div className="bg-muted flex h-10 w-10 items-center justify-center rounded-full p-3">
                                <Icon iconName="Journals" size="2xl" />
                            </div>
                            <div className="">
                                <div className="font-medium">{__('Terstruktur')}</div>
                                <div className="text-muted-fg text-justify text-xs font-light">
                                    {__(
                                        'Pembelajaran seri disusun dengan alur yang rapi, jadi semua aspek penting dari topik pasti kebahas. Ini bikin kamu nggak bingung atau ketinggalan info, karena tiap bagian saling melengkapi.',
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="flex cursor-default space-x-4">
                            <div className="bg-muted flex h-10 w-10 items-center justify-center rounded-full p-3">
                                <Icon iconName="Bullseye" size="2xl" />
                            </div>
                            <div className="">
                                <div className="font-medium">{__('Fokus')}</div>
                                <div className="text-muted-fg text-justify text-xs font-light">
                                    {__(
                                        'Belajar secara seri bikin fokus kamu tetap terjaga karena tiap sesi berhubungan sama yang sebelumnya. Ini bikin kamu nggak gampang hilang konsentrasi atau kelewatan detail penting yang sering terjadi kalau belajar topik secara acak.',
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="flex cursor-default space-x-4">
                            <div className="bg-muted flex h-10 w-10 items-center justify-center rounded-full p-3">
                                <Icon iconName="Cpu" size="2xl" />
                            </div>
                            <div className="">
                                <div className="font-medium">{__('Mendalam')}</div>
                                <div className="text-muted-fg text-justify text-xs font-light">
                                    {__(
                                        'Dengan belajar secara seri, materinya disajikan step by step. Ini bikin kamu bisa paham lebih dalam karena tiap topik dibahas lebih detail.',
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="flex cursor-default space-x-4">
                            <div className="bg-muted flex h-10 w-10 items-center justify-center rounded-full p-3">
                                <Icon iconName="Lightbulb" size="2xl" />
                            </div>
                            <div className="">
                                <div className="font-medium">{__('Nempel')}</div>
                                <div className="text-muted-fg text-justify text-xs font-light">
                                    {__(
                                        'Karena materinya disusun dengan urutan yang pas, informasi jadi lebih gampang diingat dan dipraktekin. Belajar secara seri bikin ingatan dan pemahaman kamu jadi lebih nempel dan tahan lama.',
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="flex cursor-default space-x-4">
                            <div className="bg-muted flex h-10 w-10 items-center justify-center rounded-full p-3">
                                <Icon iconName="Stars" size="2xl" />
                            </div>
                            <div className="">
                                <div className="font-medium">{__('Gratis')}</div>
                                <div className="text-muted-fg text-justify text-xs font-light">
                                    {__(
                                        'Semua seri ini gratis, jadi kamu bisa belajar tanpa pusing soal biaya. Nikmati setiap sesi tanpa harus khawatir keluar uang, dan ambil semua manfaatnya tanpa beban tambahan!',
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <section id="series">
                        <Card.Header className="cursor-default px-0">
                            <div className="text-fg text-base font-medium md:text-lg">{__('Seri Terbaru')}</div>
                            <div className="text-muted-fg text-sm">
                                {__('Kumpulan seri paling baru yang ada disini.')}
                            </div>
                        </Card.Header>
                        <div className="grid flex-1 grid-cols-1 gap-6 md:grid-cols-3">
                            {series.length > 0 ? (
                                <>
                                    {series.map((item) => (
                                        <div key={item.id}>
                                            <Link
                                                href={route('series.show', [item.slug])}
                                                className="relative h-56 w-full"
                                            >
                                                <Badge
                                                    className="absolute top-2 left-2 z-10 flex w-12 justify-center"
                                                    shape="circle"
                                                    intent="secondary"
                                                >
                                                    {(item.user_articles_count / item.series_details_count) * 100}%
                                                </Badge>
                                                <img
                                                    alt={item.title}
                                                    loading="lazy"
                                                    src={item.thumbnail}
                                                    className="h-56 w-full rounded-xl border object-cover object-center"
                                                />
                                            </Link>
                                            <div className="space-y-3 px-2 pt-3">
                                                <Link
                                                    href={route('series.show', [item.slug])}
                                                    className="text-md w-full pb-0.5 font-medium"
                                                >
                                                    {item.title}
                                                </Link>
                                                <div className="mt-1.5 flex justify-between">
                                                    <div className="flex flex-row items-center space-x-2">
                                                        <Avatar
                                                            size="small"
                                                            src={item.user.gravatar}
                                                            initials={item.user.name?.substring(0, 1)}
                                                            aria-label="Gravatar author"
                                                        />
                                                        <div className="text-muted-fg text-sm">{item.user.name}</div>
                                                    </div>
                                                    <Badge intent="secondary" shape="circle">
                                                        {__('{n} Seri', { number: item.series_chapters_count })}
                                                    </Badge>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            ) : (
                                <div className="text-muted-fg text-xs md:text-sm">{__('Belum ada seri tersedia.')}</div>
                            )}
                        </div>
                    </section>
                    <Link
                        href="/series"
                        className={cn(
                            buttonStyles({ intent: 'primary', shape: 'circle' }),
                            'group mt-6 w-fit px-6 md:mt-10',
                        )}
                    >
                        {__('Lihat semua seri sekarang')}
                        <Icon
                            iconName="ArrowRightShort"
                            size="2xl"
                            className="transition-all duration-200 group-hover:ml-2.5"
                            intent="white"
                        />
                    </Link>
                </Container>
            </div>
        </>
    )
}
