import { Footer } from '@/components/footer'
import { Navbar } from '@/components/navbar'
import { Toast } from '@/components/ui/toast'
import { PropsWithChildren } from 'react'

export default function AppLayout({ children }: PropsWithChildren) {
    return (
        <>
            <Navbar />
            <Toast />
            <main className="mb:mb-12 mb-8 flex min-h-[calc(100vh-calc(var(--spacing)*16))] w-full flex-1 flex-col gap-4 lg:mb-20">
                {children}
            </main>
            <Footer />
        </>
    )
}
