import { __ } from '@/lib/utils'
import { cn } from '@/utils/classes'

export default function Loading({ className }: { className?: string }) {
    return (
        <div
            className={cn(
                'border-muted bg-muted/20 flex items-center justify-center rounded-xl border text-sm italic drop-shadow-sm saturate-50',
                className,
            )}
        >
            {__('Loading...')}
        </div>
    )
}
