import { Container } from '@/components/container'
import { HomeSperator } from '@/components/home-sperator'
import { Icon } from '@/components/icons'
import Loading from '@/components/loading'
import { MetaTags } from '@/components/meta-tags'
import { __ } from '@/lib/utils'
import { ArticleCard } from '@/pages/articles/partials/article-card'
import { ArticlesData, SeriesData } from '@/types/generated'
import { cn } from '@/utils/classes'
import { Head } from '@inertiajs/react'
import { lazy, Suspense } from 'react'
import { Link } from 'react-aria-components'
import { buttonStyles, Card } from 'ui'

const HeroCarousel = lazy(() => delayForDemo(import('@/components/hero-carousel')))

// const FlipWords = lazy(() => import('@/components/flip-words').then((module) => ({ default: module.FlipWords })))
export default function Home(props: any) {
    const {
        articles,
        popular_articles,
        series,
    }: { articles: Array<ArticlesData>; popular_articles: Array<ArticlesData>; series: Array<SeriesData> } = props

    return (
        <>
            <Head title={__('Mari kita belajar bersama')} />
            <MetaTags title="Mari kita belajar bersama" description="Mari kita belajar bersama" url={route('home')} />
            <Hero />
            <Container className="space-y-16 pt-6 sm:space-y-24">
                <section id="populer">
                    <Card.Header className="cursor-default px-0">
                        <div className="text-fg text-base font-medium md:text-xl">{__('Artikel Populer')}</div>
                        <div className="text-muted-fg text-sm md:text-base">
                            {__('Kumpulan artikel terpopuler yang ada disini.')}
                        </div>
                    </Card.Header>

                    {popular_articles.length > 1 ? (
                        <div className="grid gap-y-8 sm:grid-cols-2 sm:gap-x-8 lg:grid-cols-4">
                            {popular_articles.map((article: ArticlesData) => (
                                <ArticleCard key={article.id} article={article} />
                            ))}
                        </div>
                    ) : (
                        <div className="text-muted-fg text-xs md:text-sm">{__('Belum ada artikel tersedia.')}</div>
                    )}
                </section>
                <section id="latest">
                    <Card.Header className="cursor-default px-0">
                        <div className="text-fg text-base font-medium md:text-xl">{__('Artikel Terbaru')}</div>
                        <div className="text-muted-fg text-sm md:text-base">
                            {__('Kumpulan artikel paling baru yang ada disini.')}
                        </div>
                    </Card.Header>
                    {articles.length > 1 ? (
                        <div className="grid gap-y-8 sm:grid-cols-2 sm:gap-x-8 lg:grid-cols-4">
                            {articles.map((article: ArticlesData) => (
                                <ArticleCard key={article.id} article={article} />
                            ))}
                        </div>
                    ) : (
                        <div className="text-muted-fg text-xs md:text-sm">{__('Belum ada artikel tersedia.')}</div>
                    )}
                </section>
            </Container>
            <HomeSperator className="py-4" series={series} />
        </>
    )
}

function Hero() {
    return (
        <div className="border-muted/70 mx-auto w-full border-b py-8 md:py-24">
            <Container>
                <svg
                    className="absolute inset-0 -z-10 hidden h-full w-full stroke-violet-600/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)] md:block"
                    aria-hidden="true"
                >
                    <defs>
                        <pattern
                            id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
                            width={200}
                            height={200}
                            x="50%"
                            y={-1}
                            patternUnits="userSpaceOnUse"
                        >
                            <path d="M.5 200V.5H200" fill="none" />
                        </pattern>
                    </defs>
                    <rect
                        width="100%"
                        height="100%"
                        strokeWidth={0}
                        fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)"
                    />
                </svg>
                <div
                    className="absolute top-10 right-[calc(50%-4rem)] -z-10 hidden transform-gpu blur-3xl sm:right-[calc(50%-18rem)] md:block lg:top-[calc(50%-30rem)] lg:right-48 xl:right-[calc(50%-24rem)]"
                    aria-hidden="true"
                >
                    <div
                        className="from-primary to-fg/50 aspect-1108/632 w-[69.25rem] bg-linear-to-r opacity-25 dark:opacity-30"
                        style={{
                            clipPath:
                                'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)',
                        }}
                    />
                </div>
                <div className="grid grid-cols-1 items-center gap-x-4 tracking-wide lg:grid-cols-2">
                    <div className="text-foreground w-[95%] text-left">
                        <div className="inline-block text-4xl font-bold tracking-tight sm:text-6xl md:text-7xl">
                            {__('Ayo kita pelajari tentang')} <span className="text-primary">{__('Visualisasi')}</span>
                            {/*<FlipWords*/}
                            {/*    words={['Programming', 'Visualisasi', 'Design', 'Data', 'Tableau']}*/}
                            {/*    className="ml-1"*/}
                            {/*/>*/}
                        </div>
                        <p className="text-muted-fg mt-6 hidden text-base md:block md:text-lg md:leading-relaxed">
                            {__(
                                'Mengubah data menjadi visualisasi yang menarik dan mudah dipahami. Di sini, kami membuat informasi kompleks jadi lebih jelas dan menyenangkan, membantu kamu melihat pola dan cerita yang tersembunyi di balik data. Ayo kita bersama belajar dengan bersama, gausah menunggu sampai mau, karna, kapan maunya?',
                            )}
                        </p>
                        <div className="hidden md:block">
                            <Link
                                href="/articles"
                                className={cn(buttonStyles({ intent: 'primary', shape: 'circle' }), 'group mt-14 px-6')}
                            >
                                {__('Mulai jelajahi artikel')}
                                <Icon
                                    iconName="ArrowRightShort"
                                    size="2xl"
                                    className="transition-all duration-200 group-hover:ml-2.5"
                                    intent="white"
                                />
                            </Link>
                        </div>
                    </div>
                    <div className="hidden lg:block">
                        <div className="flex justify-end">
                            <Suspense fallback={<Loading className="h-[450px] w-[450px]" />}>
                                <HeroCarousel />
                            </Suspense>
                            {/* <HeroChart className="h-[450px] w-[450px]" /> */}
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}

function delayForDemo(promise: Promise<any>) {
    return new Promise((resolve) => {
        setTimeout(resolve, 100)
    }).then(() => promise)
}
