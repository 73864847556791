import { cn } from '@/utils/classes'
import { Icon } from '@/components/icons'

interface VerifyBadgeProps {
    check?: boolean
    className?: string
}

export function VerifyBadge({ check, className }: VerifyBadgeProps) {
    return (
        <div className={cn('absolute -top-1 -right-1', className)}>
            {check && (
                <div className="relative">
                    <div className="absolute top-1/2 left-1/4 -z-10 size-2 bg-white"></div>
                    <Icon iconName="PatchCheckFill" className="fill-primary size-4" />
                </div>
            )}
        </div>
    )
}
